$(document).ready(function() {
  var opener;
  $(".modal").on("show.bs.modal", function(e){
    opener = e.relatedTarget
  });
  $("#remove-dictionary.modal .submit-remove").click(function(){
    delete_form = $(opener).siblings(".remove-dictionaries").first();
    if(delete_form.length){
      delete_form.click();
      $(".modal").modal("toggle");
    }
  });

  $(".file").click(function(e){
    upload_file = $(this).parent(".row").children(".input-file").get(0);
    if(upload_file){
      $(upload_file).children(".upload-file").get(0).click();
    }
  })

  $(".upload-file").change(function(e){
    var fileName = e.target.files[0].name;
    // $(this).parents(".col-md-4").siblings(".col-md-8").children(".file_name").html(fileName);
    $(this).closest("div.row").next(".row").children(".file-name").html(fileName)
  });

  $(".dictionary").on("click", ".remove-dictionary", function(){
    var delete_keyword = $(this).parent().siblings(".select-keyword").children("select").val()

    if (delete_keyword.length > 0) {
      $("#remove-dictionary.modal, #remove-keyword.modal").modal("toggle", $(this));
    }
  })
});
