// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"
import "bootstrap"
import "bootstrap-datepicker"
import "select2"
import "select2/dist/css/select2.css"

Rails.start()
ActiveStorage.start()

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("packs/dictionaries.js")
require("packs/reports.js")

$(window).on("load", function() {
  $("a:not([data-remote=true], [href='#'], [target='_blank'], [href=''])").click(function(e){
    $("#loader").show();
    if (e.ctrlKey || e.metaKey) {$("#loader").hide();}
    window.onunload = function(){$("#loader").hide();};
  })

  $("form[data-remote=true]").on("ajax:before", function() {
    $("#loader").show();
  })

  $("form[data-remote=true]").on("ajax:complete", function() {
    $("#loader").fadeOut("slow");
  })

  $(document).ajaxStart(function() {
    $("#loader").show();
  })

  $(document).ajaxComplete(function() {
    $("#loader").fadeOut("slow");
  });

  $(document).ajaxStop(function() {
    $("#loader").fadeOut("slow");
  });

  $(".select-2").select2({
    minimumResultsForSearch: -1
  })

  $(".select-2-search").select2({
    width: "100%",
    "language": {
      "noResults": function() {
        return "企業情報は見つかりませんでした"
      }
    }
  })

  $(".select-2-modal").select2({
    dropdownParent: $("#add-keyword"),
    width: "100%",
    "language": {
      "noResults": function() {
        return "サブディメンション情報は見つかりませんでした";
      }
    }
  })

  $('.submit-loader').click(function(){
    $("#loader").show();
  });
});
