$(document).ready(function() {
  var opener;
  $(".modal").on("show.bs.modal", function(e){
    opener = e.relatedTarget
  });
  $(".ex-datepicker").datepicker({
    format: "yyyy/mm/dd",
    todayHighlight: true,
    autoclose: true,
    endDate: new Date()
  });
  $("#add-keyword.modal .btn-add-keyword").click(function(){
    var keyword = $("#add-keyword .modal-body input[name='keyword']").val()
    var keyword_position = $("#add-keyword .modal-body input[name='keyword_position']").val()
    var dimension = $("#add-keyword .modal-body input[name='dimension']").val()
    var sub_dimension = $("#add-keyword .modal-body input[name='sub_dimension']").val()
    var select_option_text = keyword

    $("#sub_dimension").removeClass("is-invalid")
    $("#add-keyword .invalid-feedback").html()

    if($("#add-keyword .modal-body input[name='keyword_proper']").length > 0) {
      dimension = $("#sub_dimension").val()

      if(!dimension) {
        $("#sub_dimension").addClass("is-invalid")
        $("#add-keyword .invalid-feedback").html("サブディメンションを選択してください")
        return false
      }

      sub_dimension = $("#sub_dimension").find("option:selected").text()
      $("#sub_dimension").val($("#sub_dimension option:first").val());
      select_option_text = sub_dimension + " - " + keyword
    }

    $(".select-keyword ." + keyword_position + " select option[value='" + keyword + "']").remove();
    $(".added-keyword ." + keyword_position + " select").append($("<option dim-main=" + dimension + " dim-sub=" + sub_dimension + ">").val(keyword).text(select_option_text))

    var synonyms = getAllKeywords()
    localStorage.setItem("synonyms_" + report_id, JSON.stringify(synonyms))

    $("#add-keyword").modal("toggle");
  });

  $("#remove-keyword.modal .submit-remove").click(function(){
    var sub_dimension = $(opener).parent().siblings(".select-keyword").children("select")
    var selected_keywords = $(sub_dimension).val()
    var keyword_position = $(sub_dimension).attr("data-possition")
    for (let x in selected_keywords) {
      var keyword = selected_keywords[x]
      $(".added-keyword ." + keyword_position + " select option[value='" + keyword + "']").remove();
      $(".select-keyword ." + keyword_position + " select").append($('<option>').val(keyword).text(keyword))
    }

    var synonyms = getAllKeywords()
    localStorage.setItem("synonyms_" + report_id, JSON.stringify(synonyms))

    $("#remove-keyword").modal("toggle");
  });

  $(".select-keyword").on("click", ".add-report-keyword", function(e){
    var keyword = $(this).val()

    if (!keyword) {
      return false
    }

    var option_selected = $(this).find("option:selected").text()

    var dimension = $(this).attr("dimension")
    var sub_dimension = $(this).attr("sub-dimension")
    var keyword_position = $(this).attr("data-possition")
    if(keyword == option_selected) {
      var keywords = JSON.parse(localStorage.getItem(key_type + "_" + report_id))
      var select_keywords = keywords[dimension][sub_dimension][keyword]
    } else {
      var select_keywords = JSON.parse(keyword)
    }

    $(".content-report .modal-body .keyword-sentences").html(null);
    select_keywords.forEach(function(select_keyword) {
      if($.type(select_keyword) == "string") {
        var select_keyword = JSON.parse(select_keyword)
      }

      var content = '<div class="row p-1"><div class="col text-end pe-3">' + padPre(select_keyword["pre"])
        + '</div><div class="col-auto p-0 text-danger text-center">' + select_keyword["match"]
        + '</div><div class="col text-left ps-3">' + padPost(select_keyword["post"]) + '</div></div>'

      $(".content-report .modal-body .keyword-sentences").append(content);
    })

    if($(this).hasClass("keyword-proper")) {
      $(".select-sub-dic").removeClass("d-none").show();
      $("#sub_dimension").removeClass("is-invalid")
      $("#add-keyword .invalid-feedback").html()
      $("#add-keyword .modal-body .keyword-sentences").append("<input type='hidden' name='keyword_proper' value='" + true + "'>")
    } else {
      $(".select-sub-dic").hide();
    }
    $("#add-keyword .modal-body .keyword-sentences").append("<input type='hidden' name='dimension' value='" + dimension + "'>")
    $("#add-keyword .modal-body .keyword-sentences").append("<input type='hidden' name='sub_dimension' value='" + sub_dimension + "'>")
    $("#add-keyword .modal-body .keyword-sentences").append("<input type='hidden' name='keyword' value='" + keyword + "'>")
    $("#add-keyword .modal-body .keyword-sentences").append("<input type='hidden' name='keyword_position' value='" + keyword_position + "'>")
    $("#add-keyword, #detail-keyword").modal("show");
    $("select.add-report-keyword").val(null);
    $(this).find("option").filter(function(){return $(this).text() === option_selected}).prop("selected", true);
  })

  if($("#step_2, #step_3").length) {
    localStorage = window.localStorage
    report_id = $("#step_2, #step_3, #step_4").attr("report_id")

    if($("#step_2").length) {
      var keywords = JSON.parse(localStorage.getItem("check_keywords_" + report_id))
      key_type = "check_keywords"
      var store_keyword = $("#step_2").attr("fetch-keywords") == "true"
    } else {
      var keywords = JSON.parse(localStorage.getItem("candidates_" + report_id))
      key_type = "candidates"
    }

    storeKeywords(report_id, keywords, key_type, store_keyword);

    if($("#step_3").length) {
      var synonyms = JSON.parse(localStorage.getItem("synonyms_" + report_id))

      if(synonyms) {
        addOpionsSynonyms(synonyms)
      }
    }
  }

  if($("#step_5, #step_1").length) {
    var report_id = $("#step_5, #step_1").attr("report_id")
    removeKeyWords(report_id);
  }

  $("form#step_4").submit(function(eventObj) {
    var synonyms = getAllKeywords()
    $(this).append("<input type='hidden' name='t_report[synonyms]' value='" + JSON.stringify(synonyms) + "'>");
    return true;
  });
})

function storeKeywords(report_id, keywords, key_type, store_keyword) {
  if (keywords == null || store_keyword) {
    $("#loader").show();
    $.ajax({
      url: "/reports/" + report_id + "/keywords",
      type: "get"
    }).done(function(data){
      for (var key in data) {
        var value = data[key];
        localStorage.setItem(key + "_" + report_id, JSON.stringify(value));
      }
      addOpionsSelect(data[key_type])
    })
  } else {
    addOpionsSelect(keywords)
  }
}

function removeKeyWords(report_id) {
  var keysToRemove = ["candidates_" + report_id, "check_keywords_" + report_id, "synonyms_" + report_id];

  keysToRemove.forEach(function(key) {
    localStorage.removeItem(key);
  })
}

function addOpionsSelect(keywords) {
  $(".select-keyword select.add-report-keyword").each(function(index, element) {
    var dimension = $(element).attr("dimension")
    var sub_dimension = $(element).attr("sub-dimension")

    if(keywords[dimension]) {
      var select_keywords = keywords[dimension][sub_dimension]
      for (keyword in select_keywords) {
        $(element).append($('<option>').val(keyword).text(keyword))
      }
    }
  })
}

function addOpionsSynonyms(synonyms) {
  $(".added-keyword select").each(function(index, element) {
    var dimension = $(element).attr("dimension")
    var sub_dimension = $(element).attr("sub-dimension")
    var dict_kind = $(element).attr("dict-kind")
    var keyword_position = $(element).attr("data-possition")

    synonyms.forEach(function(item) {
      var keyword = item["word"]
      if(dict_kind == "固有名詞" && item["dict_kind"] == dict_kind) {
        $(element).append($("<option dim-main=" + item["dim_main"] + " dim-sub=" + item["dim_sub"] + ">").val(keyword).text(item["dim_sub"] + " - " + keyword))
        $(".select-keyword ." + keyword_position + " select option[value='" + keyword + "']").remove();
      } else {
        if(item["dict_kind"] == "類義語" && item["dim_sub"] == sub_dimension) {
          $(element).append($("<option dim-main=" + dimension + " dim-sub=" + sub_dimension + ">").val(keyword).text(keyword))
          $(".select-keyword ." + keyword_position + " select option[value='" + keyword + "']").remove();
        }
      }
    })
  })
}

function getAllKeywords() {
  result = []
  $(".added-keyword select").each(function(index, element) {
    $(element).find('option').each(function() {
      keyword = {}
      keyword["dict_kind"] = $(element).attr("dict-kind")
      keyword["dim_main"] = $(this).attr("dim-main")
      keyword["dim_sub"] = $(this).attr("dim-sub")
      keyword["word"] = $(this).val()

      result.push(keyword)
    })
  })

  return result
}

function padPost(post) {
  var pad_post = post
  if(post.length > 20) {
    pad_post = post.slice(0, 20).padEnd(23, ".")
  }
  return pad_post
}

function padPre(pre) {
  var pad_pre = pre
  if(pre.length > 20) {
    pad_pre = pre.slice(pre.length - 20, pre.length).padStart(23, ".")
  }
  return pad_pre
}
